import { MonitoringSystem, MonitoringSystemType } from '@serverfarm/nocd-commons';
import { Maybe } from 'monet';

import { DIALOG_IN_PROGRESS_INITIAL_STATE, IN_PROGRESS_INITIAL_STATE, PAGINATION_INITIAL_STATE } from '../../redux/constants';

import {
  MonitoringSystemSavingState,
  MonitoringSystemDeletingState,
  MonitoringSystemEditingState,
  MonitoringSystemsListingState,
  MonitoringSystemsDialogState,
  MonitoringSystemIncidentInformationDialogState,
  MonitoringSystemIncidentAcknowledgeState,
  MonitoringSystemIncidentClosingState,
} from './entities';

const monitoringSystem: MonitoringSystem = {
  id: '',
  name: '',
  site: '',
  code: '',
  type: '' as MonitoringSystemType,
  frequency: 0,
  quantity: 0,
  active: true,
  parser: {
    normal_def: '',
    offnormal_def: '',
    sev1_def: '',
    sev2_def: '',
    sev3_def: '',
    sev4_def: '',
    sev5_def: '',
    timezone: '',
  },
  updatedAt: new Date(),
  lastEventReceivedAt: new Date(),
  failing: false,
  lastIncident: {
    alertId: '',
    monitoringSystemId: '',
    status: '',
    createdAt: new Date(),
  },
};

const monitoringSystemsListingState: MonitoringSystemsListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  monitoringSystems: Maybe.None(),
  exportedMonitoringSystems: Maybe.None(),
  filter: Maybe.Some({}),
  hiddenColumns: [],
  sort: Maybe.None(),
  id: Maybe.None(),
  status: 'green',
};

const monitoringSystemsEditingState: MonitoringSystemEditingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  monitoringSystem: Maybe.None(),
};

const monitoringSystemsSavingState: MonitoringSystemSavingState = {
  ...IN_PROGRESS_INITIAL_STATE,
};

const monitoringSystemsDeletingState: MonitoringSystemDeletingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  monitoringSystemId: Maybe.None(),
};

const monitoringSystemsDialogState: MonitoringSystemsDialogState = {
  ...DIALOG_IN_PROGRESS_INITIAL_STATE,
};

const monitoringSystemIncidentDialogState: MonitoringSystemIncidentInformationDialogState = {
  ...DIALOG_IN_PROGRESS_INITIAL_STATE,
  monitoringSystem: Maybe.None(),
};

const monitoringSystemIncidentAcknowledgeState: MonitoringSystemIncidentAcknowledgeState = {
  ...IN_PROGRESS_INITIAL_STATE,
  alertId: Maybe.None(),
};

const monitoringSystemIncidentClosingState: MonitoringSystemIncidentClosingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  alertId: Maybe.None(),
};

export const MONITORING_SYSTEMS_INITIAL_STATE = {
  monitoringSystem,
  monitoringSystemsListingState,
  monitoringSystemsEditingState,
  monitoringSystemsDeletingState,
  monitoringSystemsSavingState,
  monitoringSystemsDialogState,
  monitoringSystemIncidentDialogState,
  monitoringSystemIncidentAcknowledgeState,
  monitoringSystemIncidentClosingState,
};
