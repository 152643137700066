import moment from 'moment';
// @ts-ignore: No declarations available
import { Maybe, Some } from 'monet';
import * as React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { connect } from 'react-redux';
// @ts-ignore: No declarations available
import { returntypeof } from 'react-redux-typescript';
import { Button, ButtonGroup, Spinner, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import AssetMaintenanceDialog from '../assets/AssetMaintenanceDialog';
import AssetMappingDialog from '../assets/AssetMappingDialog';
import { fetchMonitoringSystemsStatus } from '../monitoring-systems/data/monitoring-systems';
import MonitoringSystemEditor from '../monitoring-systems/MonitoringSystemEditor';
import { fetchSitesGroups, fetchSites } from '../sites';

import AlertNocInCommandRequestModal from './AlertNocInCommandRequestModal';
import AlertNotificationsModal from './AlertNotificationsModal';
import AlertsList, { AlertListsProps } from './AlertsList';
import AlertSourceInformationModal from './AlertSourceInformationModal';
import AlertStakeholderNotificationModal from './AlertStakeholderNotificationModal';
import AlertWorkLogModal from './AlertWorkLogModal';
import AssetsInMaintenanceModal from './AssetsInMaintenanceModal';
import {
  fetchAlerts,
  updateAlertListingFilter,
  updateAlertListingSort,
  resetAlerts,
  getTotalTrackedAlerts,
  openAssetsInMaintenance,
  openMonitoringSystems,
} from './data/alerts';
import MonitoringSystemsModal from './MonitoringSystemsModal';

// const logger = logdown('component:Alerts');

class Component extends React.Component<Props, unknown> {
  refresh() {
    const updatedAt = this.props.alertsListing.filter.flatMap((filter: any) => Maybe.fromUndefined(filter.updatedAt)).getOrElse({ from: '', to: '' });
    const from = updatedAt.flatMap((date: any) => Maybe.fromUndefined(date.from).getOrElse(''));
    const to = updatedAt.flatMap((date: any) => Maybe.fromUndefined(date.to).getOrElse(''));

    const today = moment().startOf('day');
    const toDate = moment(to || new Date())
      .startOf('day')
      .isSame(today)
      ? moment().toDate()
      : to;

    const filter = this.props.alertsListing.filter.map((filter: any) => ({
      ...filter,
      updatedAt: Some({ from, to: toDate }),
    }));
    this.props.fetchMonitoringSystemsStatus();
    this.props.fetchSitesGroups();
    this.props.fetchSites();
    this.props.fetchAlerts(filter, this.props.alertsListing.page, this.props.alertsListing.sizePerPage, this.props.alertsListing.sort);
    this.props.getTotalTrackedAlerts();
  }

  // reset() {
  //   this.props.updateAlertListingFilter(Maybe.none(), 1, this.props.alertsListing.sizePerPage);
  //   this.props.updateAlertListingSort(Maybe.none());
  //   this.props.fetchAlerts(Maybe.none(), 1, this.props.alertsListing.sizePerPage, Maybe.none());
  // }

  // isFilterSet(): boolean | undefined {
  //   return this.props.alertsListing.filter.exists((filter) => {
  //     const res = Object.keys(filter).reduce((memo, key): boolean => {
  //       const v = (filter as { [name: string]: any })[key];
  //       if (v && Maybe.isInstance(v)) {
  //         return memo || !!v.orUndefined();
  //       }
  //       return memo || !!v;
  //     }, false);
  //     return res;
  //   });
  // }
  componentDidUpdate(prevProps: Props & AlertListsProps) {
    const isMonitoringSystemEditorClosed = prevProps.monitoringSystemEditing.isInProgress && !this.props.monitoringSystemEditing.isInProgress;
    const isMonitoringSystemDeleteFinished = prevProps.monitoringSystemDeleting.isInProgress && !this.props.monitoringSystemDeleting.isInProgress;
    if (isMonitoringSystemEditorClosed || isMonitoringSystemDeleteFinished) {
      this.props.fetchMonitoringSystemsStatus();
    }
  }
  componentDidMount() {
    this.props.fetchMonitoringSystemsStatus();
  }

  public render() {
    return (
      <div className="content">
        <Button
          data-testid="alerts-list-reload-button"
          id="alerts-list-reload-button"
          color="secondary"
          size="sm"
          disabled={this.props.alertsListing.isInProgress}
          onClick={this.refresh.bind(this)}
        >
          <Spinner size="sm" hidden={!this.props.alertsListing.isInProgress} /> Refresh
        </Button>
        {/* <Button
          id="alerts-list-reset-button"
          color="secondary"
          size="sm"
          disabled={this.props.alertsListing.isInProgress}
          // hidden={!this.isFilterSet.bind(this)()}
          onClick={this.reset.bind(this)}
          title="Reset to see the latest Alerts"
        >
          Reset{' '}
          {this.props.alertsListing.notificationsNotShown ? (
            <Badge className="ml-1" color="danger">
              {this.props.alertsListing.notificationsNotShown}
            </Badge>
          ) : (
            ''
          )}
        </Button> */}
        <ButtonGroup>
          <Button
            className="btn-contrast"
            size="sm"
            onClick={() => {
              this.props.updateAlertListingFilter(
                this.props.alertsListing.filter.map((filter) => ({ ...filter, isNotMapped: undefined, isTracked: undefined })),
                this.props.alertsListing.page,
                this.props.alertsListing.sizePerPage,
              );
            }}
            active={this.props.alertsListing.filter.exists((f) => f.isNotMapped === undefined && f.isTracked === undefined)}
          >
            All
          </Button>
          <Button
            className="btn-contrast"
            size="sm"
            onClick={() => {
              this.props.updateAlertListingFilter(
                this.props.alertsListing.filter.map((filter) => ({ ...filter, isNotMapped: false, isTracked: undefined })),
                this.props.alertsListing.page,
                this.props.alertsListing.sizePerPage,
              );
            }}
            active={this.props.alertsListing.filter.exists((f) => f.isNotMapped === false && f.isTracked === undefined)}
          >
            Mapped
          </Button>
          <Button
            className="btn-contrast"
            size="sm"
            onClick={() => {
              this.props.updateAlertListingFilter(
                this.props.alertsListing.filter.map((filter) => ({ ...filter, isNotMapped: true, isTracked: undefined })),
                this.props.alertsListing.page,
                this.props.alertsListing.sizePerPage,
              );
            }}
            active={this.props.alertsListing.filter.exists((f) => f.isNotMapped === true && f.isTracked === undefined)}
          >
            Not mapped
          </Button>
          <Button
            className="btn-contrast"
            size="sm"
            onClick={() => {
              this.props.updateAlertListingFilter(
                this.props.alertsListing.filter.map((filter) => ({ ...filter, isNotMapped: undefined, isTracked: true })),
                this.props.alertsListing.page,
                this.props.alertsListing.sizePerPage,
              );
            }}
            active={this.props.alertsListing.filter.exists((f) => f.isNotMapped === undefined && f.isTracked === true)}
          >
            Tracked
          </Button>
        </ButtonGroup>
        <Button
          className="btn-info"
          size="sm"
          onClick={() => {
            this.props.openAssetsInMaintenance();
          }}
        >
          Assets In Maintenance
        </Button>
        <div
          id="monitoring-systems-modal-button"
          style={{
            position: 'absolute',
            top: '90px',
            right: '100px',
          }}
        >
          <Icon.Globe
            style={{ color: this.props.monitoringSystemsListing.status == 'red' ? '#dc0000' : '#00ac46' }}
            size={75}
            className={`pointer ${
              this.props.monitoringSystemsListing.status == 'blink' || this.props.monitoringSystemsListing.status == 'red' ? 'blink' : ''
            }`}
            onClick={() => {
              this.props.openMonitoringSystems();
            }}
          />
        </div>
        <AlertsList {...this.props} />
        <AlertNotificationsModal {...this.props} />
        <AlertWorkLogModal {...this.props} />
        <AlertStakeholderNotificationModal {...this.props} />
        <AlertNocInCommandRequestModal {...this.props} />
        <AssetMappingDialog {...this.props} />
        <AssetMaintenanceDialog {...this.props} />
        <AlertSourceInformationModal {...this.props} />
        <AssetsInMaintenanceModal {...this.props} />
        <MonitoringSystemEditor {...this.props} />
        <MonitoringSystemsModal {...this.props} />
        <UncontrolledTooltip target={`monitoring-systems-modal-button`}>
          {this.props.monitoringSystemsListing.status == 'red'
            ? 'Failures with Monitoring Systems. Please check'
            : this.props.monitoringSystemsListing.status == 'blink'
            ? 'Monitoring Systems under maintenance'
            : 'Monitoring Systems are healthy'}
        </UncontrolledTooltip>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchAlerts,
      fetchSitesGroups,
      fetchSites,
      fetchMonitoringSystemsStatus,
      getTotalTrackedAlerts,
      updateAlertListingFilter,
      updateAlertListingSort,
      resetAlertsState: resetAlerts,
      openAssetsInMaintenance,
      openMonitoringSystems,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  sitesListing: state.sitesListing,
  monitoringSystemsListing: state.monitoringSystemsListing,
  monitoringSystemEditing: state.monitoringSystemEditing,
  monitoringSystemDeleting: state.monitoringSystemDeleting,
  sitesGroupsListing: state.sitesGroupsListing,
  alertsListing: state.alertsListing,
  companiesListing: state.companiesListing,
  alertNotificationsListing: state.alertNotificationsListing,
  alertWorkLogListing: state.alertWorkLogListing,
  alertWorkLogAdding: state.alertWorkLogAdding,
  assetMappingDialog: state.assetMappingDialog,
  stakeHolderNotificationSending: state.stakeHolderNotificationDialog,
  assetMaintenanceDialog: state.assetMaintenanceDialog,
  alertsTrackingTotalCount: state.alertsTrackingTotalCount,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, unknown>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
