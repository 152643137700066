import moment from 'moment';
import { Maybe, None } from 'monet';
import { Reducer } from 'redux';

import { MONITORING_SYSTEMS_INITIAL_STATE } from '../constants';
import {
  MonitoringSystemDeletingState,
  MonitoringSystemEditingState,
  MonitoringSystemsListingState,
  MonitoringSystemSavingState,
  MonitoringSystemIncidentInformationDialogState,
  MonitoringSystemIncidentAcknowledgeState,
  MonitoringSystemIncidentClosingState,
} from '../entities';

import {
  ActionType,
  MonitoringSystemIncidentAcknowledgeActionObject,
  MonitoringSystemIncidentClosingActionObject,
  MonitoringSystemSavingActionObject,
  MonitoringSystemsDeletingActionObject,
  MonitoringSystemsEditingActionObject,
  MonitoringSystemsFetchAction,
} from './actions';

export const monitoringSystemsListingReducer: Reducer<MonitoringSystemsListingState, MonitoringSystemsFetchAction> = (
  state = MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemsListingState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_MONITORING_SYSTEMS:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_MONITORING_SYSTEMS:
      return {
        ...state,
        isInProgress: false,
        monitoringSystems: Maybe.some(
          action.monitoringSystems.map((monitoringSystem, idx) => {
            const currentMonitoringSystems = state.monitoringSystems.orJust([]);
            const exists = currentMonitoringSystems.some((a) => {
              const found =
                a.id === monitoringSystem.id &&
                a.active === monitoringSystem.active &&
                // a.provider == monitoringSystem.provider &&
                // a.message == monitoringSystem.message &&
                // a.recipient == monitoringSystem.recipient &&
                // a.sender === monitoringSystem.sender && // moment(a.timestamp).diff(moment(alert.timestamp), 's') === 0 &&
                moment(a.updatedAt).diff(moment(monitoringSystem.updatedAt), 's') === 0;
              return found;
            });
            return { ...monitoringSystem, isNew: !exists };
          }),
        ),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
        notificationsNotShown: 0,
      };
    case ActionType.RECEIVE_EXPORTED_MONITORING_SYSTEMS:
      return {
        ...state,
        exportedMonitoringSystems: Maybe.some(
          action.exportedMonitoringSystems.map((monitoringSystem) => {
            return { ...monitoringSystem };
          }),
        ),
      };
    case ActionType.RECEIVE_MONITORING_SYSTEMS_ERROR:
      return {
        ...state,
        isInProgress: false,
        monitoringSystems: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
        notificationsNotShown: 0,
      };
    case ActionType.UPDATE_MONITORING_SYSTEMS_LISTING_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case ActionType.UPDATE_MONITORING_SYSTEMS_LISTING_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.hiddenColumns,
      };
    case ActionType.UPDATE_MONITORING_SYSTEMS_SORT_LIST:
      return {
        ...state,
        sort: action.sort,
      };
    case ActionType.RESET_MONITORING_SYSTEMS:
      return MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemsListingState;
    case ActionType.RESET_EXPORTED_MONITORING_SYSTEMS:
      return {
        ...state,
        exportedMonitoringSystems: None(),
      };
    case ActionType.RECEIVE_MONITORING_SYSTEMS_STATUS_CHECK:
      return {
        ...state,
        status: action.status,
      };
    case ActionType.RECEIVE_MONITORING_SYSTEMS_STATUS_CHECK_ERROR:
      return {
        ...state,
        exportedMonitoringSystems: None(),
      };
    default:
      return state;
  }
};

export const monitoringSystemEditingReducer: Reducer<MonitoringSystemEditingState, MonitoringSystemsEditingActionObject> = (
  state = MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemsEditingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_MONITORING_SYSTEMS_EDITING:
      return {
        ...state,
        isInProgress: true,
        monitoringSystem: action.monitoringSystem.orElse(Maybe.Some(MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystem)),
        error: Maybe.None(),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_NAME:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, name: action.name })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_SITE:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, site: action.site })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_CODE:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, code: action.code })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_TYPE:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, type: action.providerType })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_FREQUENCY:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, frequency: action.frequency })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_SEV1_DEF:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, parser: { ...t.parser, sev1_def: action.sev1_def } })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_SEV2_DEF:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, parser: { ...t.parser, sev2_def: action.sev2_def } })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_SEV3_DEF:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, parser: { ...t.parser, sev3_def: action.sev3_def } })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_SEV4_DEF:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, parser: { ...t.parser, sev4_def: action.sev4_def } })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_SEV5_DEF:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, parser: { ...t.parser, sev5_def: action.sev5_def } })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_NORMAL_DEF:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, parser: { ...t.parser, normal_def: action.normal_def } })),
      };
    case ActionType.EDIT_MONITORING_SYSTEMS_OFFNORMAL_DEF:
      return {
        ...state,
        monitoringSystem: state.monitoringSystem.map((t) => ({ ...t, parser: { ...t.parser, offnormal_def: action.offnormal_def } })),
      };
    case ActionType.COMPLETE_MONITORING_SYSTEMS_EDITING:
      return {
        ...state,
        isInProgress: false,
        monitoringSystem: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.RESET_MONITORING_SYSTEMS:
      return MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemsEditingState;
    default:
      return state;
  }
};

export const monitoringSystemSavingReducer: Reducer<MonitoringSystemSavingState, MonitoringSystemSavingActionObject> = (
  state = MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemsSavingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_MONITORING_SYSTEMS_SAVING:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.DONE_MONITORING_SYSTEMS_SAVING:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.None(),
      };
    case ActionType.FAIL_MONITORING_SYSTEMS_SAVING:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.Some(action.error),
      };
    default:
      return state;
  }
};

export const monitoringSystemDeletingReducer: Reducer<MonitoringSystemDeletingState, MonitoringSystemsDeletingActionObject> = (
  state = MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemsDeletingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_MONITORING_SYSTEMS_DELETING:
      return {
        ...state,
        isInProgress: true,
        monitoringSystemId: Maybe.Some(action.monitoringSystemId),
        error: Maybe.None(),
      };
    case ActionType.DONE_MONITORING_SYSTEMS_DELETING:
      return {
        ...state,
        isInProgress: false,
        monitoringSystemId: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.FAIL_MONITORING_SYSTEMS_DELETING:
      return {
        ...state,
        isInProgress: false,
        monitoringSystemId: Maybe.None(),
        error: action.error,
      };
    case ActionType.RESET_MONITORING_SYSTEMS:
      return MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemsDeletingState;
    default:
      return state;
  }
};

export const monitoringSystemIncidentInformationDialogReducer: Reducer<MonitoringSystemIncidentInformationDialogState, MonitoringSystemsFetchAction> =
  (state = MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemIncidentDialogState, action) => {
    switch (action.type) {
      case ActionType.OPEN_MONITORING_SYSTEM_INCIDENT_INFORMATION:
        return {
          ...state,
          isOpen: true,
          monitoringSystem: action.monitoringSystem,
        };

      case ActionType.CLOSE_MONITORING_SYSTEM_INCIDENT_INFORMATION:
        return {
          ...MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemIncidentDialogState,
        };
      default:
        return state;
    }
  };

export const monitoringSystemIncidentAcknowledgeReducer: Reducer<
  MonitoringSystemIncidentAcknowledgeState,
  MonitoringSystemIncidentAcknowledgeActionObject
> = (state = MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemIncidentAcknowledgeState, action) => {
  switch (action.type) {
    case ActionType.START_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE:
      return {
        ...state,
        isInProgress: true,
        alertId: Maybe.Some(action.alertId),
        error: Maybe.None(),
      };
    case ActionType.DONE_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE:
      return {
        ...state,
        isInProgress: false,
        alertId: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.FAIL_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE:
      return {
        ...state,
        isInProgress: false,
        alertId: Maybe.None(),
        error: action.error,
      };
    default:
      return state;
  }
};

export const monitoringSystemIncidentClosingReducer: Reducer<MonitoringSystemIncidentClosingState, MonitoringSystemIncidentClosingActionObject> = (
  state = MONITORING_SYSTEMS_INITIAL_STATE.monitoringSystemIncidentClosingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_MONITORING_SYSTEM_INCIDENT_CLOSE:
      return {
        ...state,
        isInProgress: true,
        alertId: Maybe.Some(action.alertId),
        error: Maybe.None(),
      };
    case ActionType.DONE_MONITORING_SYSTEM_INCIDENT_CLOSE:
      return {
        ...state,
        isInProgress: false,
        alertId: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.FAIL_MONITORING_SYSTEM_INCIDENT_CLOSE:
      return {
        ...state,
        isInProgress: false,
        alertId: Maybe.None(),
        error: action.error,
      };
    default:
      return state;
  }
};
