import { MonitoringSystemIncident, MonitoringSystemType } from '@serverfarm/nocd-commons';
import { Maybe, None, Some } from 'monet';
import { Action } from 'redux';

import { ErrorAction } from '../../../redux/actions';
import { ListSort, MonitoringSystem, MonitoringSystemsListFilter } from '../../entities';

export enum ActionType {
  UPDATE_MONITORING_SYSTEMS_LISTING_FILTER = 'UPDATE_MONITORING_SYSTEMS_LISTING_FILTER',
  RESET_EXPORTED_MONITORING_SYSTEMS = 'RESET_EXPORTED_MONITORING_SYSTEMS',
  RESET_MONITORING_SYSTEMS = 'RESET_MONITORING_SYSTEMS',
  RECEIVE_EXPORTED_MONITORING_SYSTEMS = 'RECEIVE_EXPORTED_MONITORING_SYSTEMS',
  REQUEST_MONITORING_SYSTEMS = 'REQUEST_MONITORING_SYSTEMS',
  RECEIVE_MONITORING_SYSTEMS = 'RECEIVE_MONITORING_SYSTEMS',
  RESET_ALL_MONITORING_SYSTEM_GLOW = 'RESET_ALL_MONITORING_SYSTEM_GLOW',
  RECEIVE_MONITORING_SYSTEMS_ERROR = 'RECEIVE_MONITORING_SYSTEMS_ERROR',
  UPDATE_MONITORING_SYSTEMS_LISTING_HIDDEN_COLUMNS = 'UPDATE_MONITORING_SYSTEMS_LISTING_HIDDEN_COLUMNS',
  UPDATE_MONITORING_SYSTEMS_SORT_LIST = 'UPDATE_MONITORING_SYSTEMS_SORT_LIST',
  RECEIVE_NEW_MONITORING_SYSTEM = 'RECEIVE_NEW_MONITORING_SYSTEM',
  OPEN_MESSAGE_INFORMATION = 'OPEN_MESSAGE_INFORMATION',
  CLOSE_MESSAGE_INFORMATION = 'CLOSE_MESSAGE_INFORMATION',
  START_MONITORING_SYSTEMS_EDITING = 'START_MONITORING_SYSTEMS_EDITING',
  EDIT_MONITORING_SYSTEMS_NAME = 'EDIT_MONITORING_SYSTEMS_NAME',
  EDIT_MONITORING_SYSTEMS_SITE = 'EDIT_MONITORING_SYSTEMS_SITE',
  EDIT_MONITORING_SYSTEMS_TYPE = 'EDIT_MONITORING_SYSTEMS_TYPE',
  EDIT_MONITORING_SYSTEMS_CODE = 'EDIT_MONITORING_SYSTEMS_CODE',
  EDIT_MONITORING_SYSTEMS_FREQUENCY = 'EDIT_MONITORING_SYSTEMS_FREQUENCY',
  EDIT_MONITORING_SYSTEMS_SEV1_DEF = 'EDIT_MONITORING_SYSTEMS_SEV1_DEF',
  EDIT_MONITORING_SYSTEMS_SEV2_DEF = 'EDIT_MONITORING_SYSTEMS_SEV2_DEF',
  EDIT_MONITORING_SYSTEMS_SEV3_DEF = 'EDIT_MONITORING_SYSTEMS_SEV3_DEF',
  EDIT_MONITORING_SYSTEMS_SEV4_DEF = 'EDIT_MONITORING_SYSTEMS_SEV4_DEF',
  EDIT_MONITORING_SYSTEMS_SEV5_DEF = 'EDIT_MONITORING_SYSTEMS_SEV5_DEF',
  EDIT_MONITORING_SYSTEMS_NORMAL_DEF = 'EDIT_MONITORING_SYSTEMS_NORMAL_DEF',
  EDIT_MONITORING_SYSTEMS_OFFNORMAL_DEF = 'EDIT_MONITORING_SYSTEMS_OFFNORMAL_DEF',
  COMPLETE_MONITORING_SYSTEMS_EDITING = 'COMPLETE_MONITORING_SYSTEMS_EDITING',
  START_MONITORING_SYSTEMS_SAVING = 'START_MONITORING_SYSTEMS_SAVING',
  DONE_MONITORING_SYSTEMS_SAVING = 'DONE_MONITORING_SYSTEMS_SAVING',
  FAIL_MONITORING_SYSTEMS_SAVING = 'FAIL_MONITORING_SYSTEMS_SAVING',
  START_MONITORING_SYSTEMS_DELETING = 'START_MONITORING_SYSTEMS_DELETING',
  DONE_MONITORING_SYSTEMS_DELETING = 'DONE_MONITORING_SYSTEMS_DELETING',
  FAIL_MONITORING_SYSTEMS_DELETING = 'FAIL_MONITORING_SYSTEMS_DELETING',
  RECEIVE_MONITORING_SYSTEMS_STATUS_CHECK = 'RECEIVE_MONITORING_SYSTEMS_STATUS_CHECK',
  RECEIVE_MONITORING_SYSTEMS_STATUS_CHECK_ERROR = 'RECEIVE_MONITORING_SYSTEMS_STATUS_CHECK',
  OPEN_MONITORING_SYSTEM_INCIDENT_INFORMATION = 'OPEN_MONITORING_SYSTEM_INCIDENT_INFORMATION',
  CLOSE_MONITORING_SYSTEM_INCIDENT_INFORMATION = 'CLOSE_MONITORING_SYSTEM_INCIDENT_INFORMATION',
  START_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE = 'START_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE',
  DONE_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE = 'DONE_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE',
  FAIL_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE = 'FAIL_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE',
  START_MONITORING_SYSTEM_INCIDENT_CLOSE = 'START_MONITORING_SYSTEM_INCIDENT_CLOSE',
  DONE_MONITORING_SYSTEM_INCIDENT_CLOSE = 'DONE_MONITORING_SYSTEM_INCIDENT_CLOSE',
  FAIL_MONITORING_SYSTEM_INCIDENT_CLOSE = 'FAIL_MONITORING_SYSTEM_INCIDENT_CLOSE',
}
type MonitoringSystemResetAction = Action<ActionType>;

type MonitoringSystemListingResetGlowAction = Action<ActionType>;

type MonitoringSystemsReceiveErrorAction = ErrorAction<ActionType>;

interface MonitoringSystemsReceiveAction extends Action<ActionType> {
  monitoringSystems: MonitoringSystem[];
  page: number;
  total: number;
}

type MonitoringSystemsStatusCheckErrorAction = ErrorAction<ActionType>;

interface MonitoringSystemsStatusCheckAction extends Action<ActionType> {
  status: string;
}

interface MonitoringSystemsRequestAction extends Action<ActionType> {
  filter: Maybe<MonitoringSystemsListFilter>;
  sizePerPage: number;
  sort: Maybe<ListSort>;
}

interface MonitoringSystemListingHiddenColumnsUpdateAction extends Action<ActionType> {
  hiddenColumns: string[];
}

interface MonitoringSystemListingSortUpdateAction extends Action<ActionType> {
  sort: Maybe<ListSort>;
}
interface MonitoringSystemIncidentInformationToggleAction extends Action<ActionType> {
  monitoringSystem: Maybe<MonitoringSystem>;
}
export const monitoringSystemListingFilterUpdate = (
  filter: Maybe<MonitoringSystemsListFilter>,
  page: number,
  sizePerPage: number,
): MonitoringSystemListingFilterUpdateAction => ({
  type: ActionType.UPDATE_MONITORING_SYSTEMS_LISTING_FILTER,
  filter,
  page,
  sizePerPage,
});

interface ExportedMonitoringSystemsReceiveAction extends Action<ActionType> {
  exportedMonitoringSystems: MonitoringSystem[];
}

interface MonitoringSystemListingFilterUpdateAction extends Action<ActionType> {
  filter: Maybe<MonitoringSystemsListFilter>;
  page: number;
  sizePerPage: number;
}

export type MonitoringSystemsFetchAction = MonitoringSystemsReceiveAction &
  ExportedMonitoringSystemsReceiveAction &
  MonitoringSystemListingFilterUpdateAction &
  MonitoringSystemsRequestAction &
  MonitoringSystemListingResetGlowAction &
  MonitoringSystemListingHiddenColumnsUpdateAction &
  MonitoringSystemListingSortUpdateAction &
  MonitoringSystemsReceiveErrorAction &
  MonitoringSystemsStatusCheckAction &
  MonitoringSystemsStatusCheckErrorAction &
  MonitoringSystemIncidentInformationToggleAction;

export const monitoringSystemListingSortUpdate = (sort: Maybe<ListSort>): MonitoringSystemListingSortUpdateAction => ({
  type: ActionType.UPDATE_MONITORING_SYSTEMS_SORT_LIST,
  sort,
});

export const monitoringSystemListingHiddenColumnsUpdate = (hiddenColumns: string[]): MonitoringSystemListingHiddenColumnsUpdateAction => ({
  type: ActionType.UPDATE_MONITORING_SYSTEMS_LISTING_HIDDEN_COLUMNS,
  hiddenColumns,
});

export const monitoringSystemsReset = (): MonitoringSystemResetAction => ({
  type: ActionType.RESET_MONITORING_SYSTEMS,
});

export const exportedMonitoringSystemsReset = (): MonitoringSystemResetAction => ({
  type: ActionType.RESET_EXPORTED_MONITORING_SYSTEMS,
});

export const monitoringSystemsReceive = (monitoringSystems: MonitoringSystem[], page: number, total: number): MonitoringSystemsReceiveAction => ({
  type: ActionType.RECEIVE_MONITORING_SYSTEMS,
  monitoringSystems,
  page,
  total,
});

export const monitoringSystemsStatusCheck = (status: string): MonitoringSystemsStatusCheckAction => ({
  type: ActionType.RECEIVE_MONITORING_SYSTEMS_STATUS_CHECK,
  status,
});

export const monitoringSystemsStatusCheckError = (error: Error): MonitoringSystemsStatusCheckErrorAction => ({
  type: ActionType.RECEIVE_MONITORING_SYSTEMS_STATUS_CHECK_ERROR,
  error,
});
export const exportedMonitoringSystemsReceive = (exportedMonitoringSystems: MonitoringSystem[]): ExportedMonitoringSystemsReceiveAction => ({
  type: ActionType.RECEIVE_EXPORTED_MONITORING_SYSTEMS,
  exportedMonitoringSystems,
});

export const monitoringSystemsRequest = (
  filter: Maybe<MonitoringSystemsListFilter>,
  sizePerPage: number,
  sort: Maybe<ListSort>,
): MonitoringSystemsRequestAction => ({
  type: ActionType.REQUEST_MONITORING_SYSTEMS,
  filter,
  sizePerPage,
  sort,
});

export const allMonitoringSystemsResetGlow = (): MonitoringSystemListingResetGlowAction => ({
  type: ActionType.RESET_ALL_MONITORING_SYSTEM_GLOW,
});

export const monitoringSystemsReceiveError = (error: Error): MonitoringSystemsReceiveErrorAction => ({
  type: ActionType.RECEIVE_MONITORING_SYSTEMS_ERROR,
  error,
});

interface MonitoringSystemStartEditingAction extends Action<ActionType> {
  monitoringSystem: Maybe<MonitoringSystem>;
}

interface MonitoringSystemEditNameAction extends Action<ActionType> {
  name: string;
}

interface MonitoringSystemEditCodeAction extends Action<ActionType> {
  code: string;
}

interface MonitoringSystemEditSiteAction extends Action<ActionType> {
  site: string;
}

interface MonitoringSystemEditTypeAction extends Action<ActionType> {
  providerType: MonitoringSystemType;
}

interface MonitoringSystemEditFrequencyAction extends Action<ActionType> {
  frequency: number;
}

interface MonitoringSystemEditSev1DefAction extends Action<ActionType> {
  sev1_def: string;
}

interface MonitoringSystemEditSev2DefAction extends Action<ActionType> {
  sev2_def: string;
}
interface MonitoringSystemEditSev3DefAction extends Action<ActionType> {
  sev3_def: string;
}
interface MonitoringSystemEditSev4DefAction extends Action<ActionType> {
  sev4_def: string;
}
interface MonitoringSystemEditSev5DefAction extends Action<ActionType> {
  sev5_def: string;
}

interface MonitoringSystemEditNormalDefAction extends Action<ActionType> {
  normal_def: string;
}
interface MonitoringSystemEditOffnormalDefAction extends Action<ActionType> {
  offnormal_def: string;
}
interface MonitoringSystemCompleteEditingAction extends Action<ActionType> {
  monitoringSystem: Maybe<MonitoringSystem>;
}

type ResetMonitoringSystemsAction = Action<ActionType>;

export type MonitoringSystemsEditingActionObject = MonitoringSystemStartEditingAction &
  MonitoringSystemEditNameAction &
  MonitoringSystemEditSiteAction &
  MonitoringSystemEditCodeAction &
  MonitoringSystemEditTypeAction &
  MonitoringSystemEditFrequencyAction &
  MonitoringSystemEditSev1DefAction &
  MonitoringSystemEditSev2DefAction &
  MonitoringSystemEditSev3DefAction &
  MonitoringSystemEditSev4DefAction &
  MonitoringSystemEditSev5DefAction &
  MonitoringSystemEditNormalDefAction &
  MonitoringSystemEditOffnormalDefAction &
  MonitoringSystemCompleteEditingAction &
  ResetMonitoringSystemsAction;

type MonitoringSystemStartSavingAction = Action<ActionType>;
interface MonitoringSystemDoneSavingAction extends Action<ActionType> {
  monitoringSystem: MonitoringSystem;
}
type MonitoringSystemFailSavingAction = ErrorAction<ActionType>;

export type MonitoringSystemSavingActionObject = MonitoringSystemStartSavingAction &
  MonitoringSystemDoneSavingAction &
  MonitoringSystemFailSavingAction &
  ResetMonitoringSystemsAction;

export type MonitoringSystemIncidentAcknowledgeActionObject = MonitoringSystemIncidentStartAcknowledgeAction &
  MonitoringSystemIncidentDoneAcknowledgeAction &
  MonitoringSystemIncidentFailAcknowledgeAction &
  ResetMonitoringSystemsAction;

export type MonitoringSystemIncidentClosingActionObject = MonitoringSystemIncidentStartClosingAction &
  MonitoringSystemIncidentDoneClosingAction &
  MonitoringSystemIncidentFailClosingAction &
  ResetMonitoringSystemsAction;

interface MonitoringSystemsStartDeletingAction extends Action<ActionType> {
  monitoringSystemId: string;
}

interface MonitoringSystemsDoneDeletingAction extends Action<ActionType> {
  monitoringSystemId: string;
}

interface MonitoringSystemsFailDeletingAction extends Action<ActionType> {
  monitoringSystemId: string;
  error: Maybe<Error>;
}

interface MonitoringSystemIncidentStartAcknowledgeAction extends Action<ActionType> {
  alertId: string;
}

interface MonitoringSystemIncidentDoneAcknowledgeAction extends Action<ActionType> {
  alertId: string;
}

interface MonitoringSystemIncidentFailAcknowledgeAction extends Action<ActionType> {
  alertId: string;
  error: Maybe<Error>;
}

interface MonitoringSystemIncidentStartClosingAction extends Action<ActionType> {
  alertId: string;
}

interface MonitoringSystemIncidentDoneClosingAction extends Action<ActionType> {
  alertId: string;
}

interface MonitoringSystemIncidentFailClosingAction extends Action<ActionType> {
  alertId: string;
  error: Maybe<Error>;
}

export type MonitoringSystemsDeletingActionObject = MonitoringSystemsStartDeletingAction &
  MonitoringSystemsDoneDeletingAction &
  MonitoringSystemsFailDeletingAction &
  ResetMonitoringSystemsAction;

export const monitoringSystemsCompleteEditing = (monitoringSystem: Maybe<MonitoringSystem>): MonitoringSystemCompleteEditingAction => ({
  type: ActionType.COMPLETE_MONITORING_SYSTEMS_EDITING,
  monitoringSystem,
});

export const monitoringSystemStartDeleting = (monitoringSystemId: string): MonitoringSystemsStartDeletingAction => ({
  type: ActionType.START_MONITORING_SYSTEMS_DELETING,
  monitoringSystemId,
});

export const monitoringSystemDoneDeleting = (monitoringSystemId: string): MonitoringSystemsDoneDeletingAction => ({
  type: ActionType.DONE_MONITORING_SYSTEMS_DELETING,
  monitoringSystemId,
});

export const monitoringSystemFailDeleting = (monitoringSystemId: string, error: Maybe<Error>): MonitoringSystemsFailDeletingAction => ({
  type: ActionType.FAIL_MONITORING_SYSTEMS_DELETING,
  monitoringSystemId,
  error,
});

export const monitoringSystemIncidentStartAcknowledge = (alertId: string): MonitoringSystemIncidentStartAcknowledgeAction => ({
  type: ActionType.START_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE,
  alertId,
});

export const monitoringSystemIncidentDoneAcknowledge = (alertId: string): MonitoringSystemIncidentDoneAcknowledgeAction => ({
  type: ActionType.DONE_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE,
  alertId,
});

export const monitoringSystemIncidentFailAcknowledge = (alertId: string, error: Maybe<Error>): MonitoringSystemIncidentFailAcknowledgeAction => ({
  type: ActionType.FAIL_MONITORING_SYSTEM_INCIDENT_ACKNOWLEDGE,
  alertId,
  error,
});

export const monitoringSystemIncidentStartClosing = (alertId: string): MonitoringSystemIncidentStartClosingAction => ({
  type: ActionType.START_MONITORING_SYSTEM_INCIDENT_CLOSE,
  alertId,
});

export const monitoringSystemIncidentDoneClosing = (alertId: string): MonitoringSystemIncidentDoneClosingAction => ({
  type: ActionType.DONE_MONITORING_SYSTEM_INCIDENT_CLOSE,
  alertId,
});

export const monitoringSystemIncidentFailClosing = (alertId: string, error: Maybe<Error>): MonitoringSystemIncidentFailClosingAction => ({
  type: ActionType.FAIL_MONITORING_SYSTEM_INCIDENT_CLOSE,
  alertId,
  error,
});

export const monitoringSystemStartEditing = (monitoringSystem: Maybe<MonitoringSystem>): MonitoringSystemStartEditingAction => ({
  type: ActionType.START_MONITORING_SYSTEMS_EDITING,
  monitoringSystem,
});

export const monitoringSystemEditName = (name: string): MonitoringSystemEditNameAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_NAME,
  name,
});

export const monitoringSystemEditCode = (code: string): MonitoringSystemEditCodeAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_CODE,
  code,
});

export const monitoringSystemEditSite = (site: string): MonitoringSystemEditSiteAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_SITE,
  site,
});

export const monitoringSystemEditType = (providerType: MonitoringSystemType): MonitoringSystemEditTypeAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_TYPE,
  providerType,
});

export const monitoringSystemEditFrequency = (frequency: number): MonitoringSystemEditFrequencyAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_FREQUENCY,
  frequency,
});

export const monitoringSystemEditSev1Def = (sev1_def: string): MonitoringSystemEditSev1DefAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_SEV1_DEF,
  sev1_def,
});

export const monitoringSystemEditSev2Def = (sev2_def: string): MonitoringSystemEditSev2DefAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_SEV2_DEF,
  sev2_def,
});

export const monitoringSystemEditSev3Def = (sev3_def: string): MonitoringSystemEditSev3DefAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_SEV3_DEF,
  sev3_def,
});

export const monitoringSystemEditSev4Def = (sev4_def: string): MonitoringSystemEditSev4DefAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_SEV4_DEF,
  sev4_def,
});

export const monitoringSystemEditSev5Def = (sev5_def: string): MonitoringSystemEditSev5DefAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_SEV5_DEF,
  sev5_def,
});

export const monitoringSystemEditNormalDef = (normal_def: string): MonitoringSystemEditNormalDefAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_NORMAL_DEF,
  normal_def,
});

export const monitoringSystemEditOffnormalDef = (offnormal_def: string): MonitoringSystemEditOffnormalDefAction => ({
  type: ActionType.EDIT_MONITORING_SYSTEMS_OFFNORMAL_DEF,
  offnormal_def,
});

export const monitoringSystemStartSaving = (): MonitoringSystemStartSavingAction => ({
  type: ActionType.START_MONITORING_SYSTEMS_SAVING,
});

export const monitoringSystemDoneSaving = (monitoringSystem: MonitoringSystem): MonitoringSystemDoneSavingAction => ({
  type: ActionType.DONE_MONITORING_SYSTEMS_SAVING,
  monitoringSystem,
});

export const monitoringSystemFailSaving = (error: Error): MonitoringSystemFailSavingAction => ({
  type: ActionType.FAIL_MONITORING_SYSTEMS_SAVING,
  error,
});

export const monitoringSystemIncidentInformationOpen = (monitoringSystem: MonitoringSystem): MonitoringSystemIncidentInformationToggleAction => ({
  type: ActionType.OPEN_MONITORING_SYSTEM_INCIDENT_INFORMATION,
  monitoringSystem: Some(monitoringSystem),
});

export const monitoringSystemIncidentInformationClose = (): MonitoringSystemIncidentInformationToggleAction => ({
  type: ActionType.CLOSE_MONITORING_SYSTEM_INCIDENT_INFORMATION,
  monitoringSystem: None(),
});
